#if __VERSION__ < 130
#define TEXTURE2D texture2D
#else
#define TEXTURE2D texture
#endif



uniform vec2 uResolution;
uniform sampler2D uTexture;
uniform vec2 uRatio;
uniform vec2 uPinchCenter;
uniform float uPinchAmount;
uniform float uPinchRadius;
uniform float uPinchSoftness;


varying vec2 vUv;


float logPinch(float dist) {
  
  // Adjust this value to control the logarithmic curve
  float scale = 0.1;
  
  // Calculate the pinch amount using a logarithmic curve
  return uPinchAmount * scale / log2(dist + 2.0);
}




void main(){	
	
	vec2 uv = gl_FragCoord.xy / uResolution.xy * uRatio;

	if (uRatio.x < 1.) {
		uv.x += ((1. - uRatio.x) / 2.);
	}
	if (uRatio.y < 1.) {
		uv.y += ((1. - uRatio.y) / 2.);
	}
	
	
	vec2 texCoord = uv;
	vec2 delta = texCoord - uPinchCenter;
	float dist = length(delta);
	
	if (dist < uPinchRadius) {
		gl_FragColor = vec4(0.,0.,0.,1.);
		//gl_FragColor = TEXTURE2D(uTexture, texCoord);
	}/*else if (dist < uPinchRadius + uPinchSoftness) {
		// Blend pixels at the edge of the circle
		float blend = (dist - uPinchRadius) / uPinchSoftness;
		vec4 color1 = texture2D(uTexture, texCoord);
		vec4 color2 = texture2D(uTexture, uPinchCenter);
		gl_FragColor = mix(color1, color2, blend);	
	} */else {
		float pinch = logPinch(dist);
    	//float pinch = uPinchAmount / dist;
		texCoord = uPinchCenter + pinch * delta;
		gl_FragColor = texture2D(uTexture, texCoord);
	}
	
}