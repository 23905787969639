#if __VERSION__ < 130
#define TEXTURE2D texture2D
#else
#define TEXTURE2D texture
#endif

varying vec2 vUv;


void main() {
	vUv = uv;
	gl_Position = vec4(position, 1.0);
	//gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}