//import * as THREE from 'three';
import * as THREE from './three.module.js';
import fragment from './shaders/fragment.glsl';
import vertex from './shaders/vertex.glsl';

export default class EGGS {
	constructor(options) {
		
		Object.assign(this, options);
	
		// Set up the HTML structure
		this.container = options.dom;
		this.backgroundImage = options.img;
		this.uMouse = new THREE.Vector2(0,0);
		
		
		this.loadBackground();
		this.addListeners();		
	}
	
	/***
	*		LOAD BACKGOUND IMAGE
	***/	
	loadBackground() {
		
		this.textureLoader = new THREE.TextureLoader()
	  	this.textureLoader.crossOrigin = ''
	  	this.textureLoader.load(this.backgroundImage, (img) => {
		 	this.texture = img;
	  		this.texture.magFilter = THREE.NearestFilter
	  		this.textureWidth = img.image.naturalWidth
	  		this.textureHeight = img.image.naturalHeight
	  		this.texture.needsUpdate = true;
	  		this.setRatio();
	  		this.createShaders();
	  		this.initStage();
		})
	}
	
	
	/***
	*		CREATE SHADERS (VERTEX & FRAGMENT)
	***/
	createShaders() {
		this.uniforms = {
			uResolution: { type: "v2", value: new THREE.Vector2() },
			uTexture: { type: "t", value: this.texture },
			uMouse: { value: new THREE.Vector2(-10,-10) },
			uRatio: { type: "v2", value: this.ratio },
			//uPinchCenter: { value: new THREE.Vector2(0.5, 0.5)},
			uPinchCenter: { value: new THREE.Vector2(this.uMouse.x, this.uMouse.y)},
			uPinchAmount: { value: 12.5 },
			uPinchRadius: { value: 0.001 },
			uPinchSoftness: { value: 0.1 },
        }

		this.vertexShader = vertex
		this.fragmentShader = fragment
		
		this.defines = {
	    	PR: window.devicePixelRatio.toFixed(1)
		}
	}
	
	
	
	/***
	*		INIT STAGE - Renderer, Camera, Scene, Geometry, Mesh
	***/
	
	initStage() {
		
		// Initialize the WebGL renderer and set its size		
		//this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer = new THREE.WebGL1Renderer({ antialias: true });
		this.container.appendChild( this.renderer.domElement );			
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.setClearColor(0x000000, 1.0);
		
		// Create the camera and set its position and perspective
		//this.camera = new THREE.PerspectiveCamera( 70, this.canvas.width / this.canvas.height, 0.01, 10 ); 
		this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 1000);
		this.camera.position.z = 1;
		//var camera = new THREE.PerspectiveCamera(45, canvas.width / canvas.height, 1, 1000);
		//camera.position.set(0, 0, 100);
		
		// Create the scene and add the camera to it
		this.scene = new THREE.Scene();
		this.scene.add(this.camera);
		
		const ambientlight = new THREE.AmbientLight(0xffffff, 2)
        this.scene.add(ambientlight);
		
		// Create the mesh and set its texture
		//this.geometry = new THREE.PlaneGeometry( 1.5, 1.5);
		this.geometry = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight)
		
		this.material = new THREE.ShaderMaterial({
			uniforms: this.uniforms,
			vertexShader: this.vertexShader,
			fragmentShader: this.fragmentShader,
			wireframe: false,
			defines: this.defines
		})
		
		this.mesh = new THREE.Mesh(this.geometry, this.material);
		this.scene.add(this.mesh);
		
		
		
		this.resize();
		
		// Render the scene and camera
		this.render()
	}
	
	/***
	*		RENDER STAGE
	***/
	render() {
		// update pinch center that follows mouse
		this.uniforms.uPinchCenter.value = new THREE.Vector2(this.uMouse.x, this.uMouse.y);
		
		this.renderer.render(this.scene, this.camera);
		this.RaF = requestAnimationFrame(this.render.bind(this))
	}
	
	
	
	/***
	*		INIT STAGE - Renderer, Camera, Scene, Geometry, Mesh
	***/
	
	addListeners() {
		window.addEventListener('resize', this.resize.bind(this), false)
		document.addEventListener('mousemove', (e) => {
			// mousemove / touchmove
			this.uMouse.x = ( e.clientX / window.innerWidth ) ;
			this.uMouse.y = 1. - ( e.clientY/ window.innerHeight );
    	});
	}
	
	
	
	/***
	*		RESIZE PAGE
	***/
	resize() {
    	this.setRatio()
		this.uniforms.uResolution.value.x = window.innerWidth
		this.uniforms.uResolution.value.y = window.innerHeight
		this.uniforms.uRatio.value = this.ratio
		//this.uniforms.uPinchCenter.value = new THREE.Vector2(this.uMouse.x, this.uMouse.y);
		this.renderer.setSize(window.innerWidth, window.innerHeight)
	}
	
	/***
	*		SET BACKGROUND IMAGE RATIO
	***/
	setRatio() {
		this.winRatio = window.innerWidth / window.innerHeight;
		this.textureRatio = this.textureWidth / this.textureHeight;
	  	this.ratio = (this.winRatio > this.textureRatio) ? new THREE.Vector2(1.0, this.textureRatio / this.winRatio) : new THREE.Vector2(this.winRatio / this.textureRatio, 1.0);	
	}
	
}


new EGGS({
	dom: document.getElementById('container'),
	//img : 'https://assets.codepen.io/90847/eggs.jpg',
	img : 'http://www.theeggs.it/img/eggs.jpg',
});